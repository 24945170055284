import React, { useEffect, useState } from "react";
import intersection from "lodash/intersection";
import { convertArrayToObject } from "../../../services/helpers";
import { setEditReservationCheckout } from "../../../redux/slices/Checkout/editReservationCheckout";
import { RateList } from "./NewEditOffersHelpers";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import IRoom from "../../../@types/IRoom";
import { RateListItem } from "../../common/CheckoutOffers/CheckoutOffersHelpers";
import { TP_REDEMPTION_RATE } from "../../../@types/Constants";
import {
  getMemberPoints,
  checkUserRedemptionEligiblity,
} from "../../../services/redemption";
import { setMemberPointsTally } from "../../../redux/slices/Member/member";
import { selectHotelRates } from "../../../redux/slices/Rate/rate";
import differenceInDays from "date-fns/differenceInDays";

const NewEditOffers = (props: any) => {
  const dispatch = useAppDispatch();
  const [offers, setOffers] = useState([]);
  const [currentRate, setCurrentRate] = useState(null);
  const checkout = useAppSelector((state) => state.editReservationCheckout);
  const rooms: { [x: string]: IRoom } = checkout.Rooms;
  const perNight = "/ night";
  const [moveSelectedOfferToTop, setMoveSelectedOfferToTop] = useState(true);
  const isMember = props.isMember;
  const [redemptiomRateFilterFlag, setredemptiomRateFilterFlag] =
    useState(false);
  const rates = useAppSelector(
    selectHotelRates([checkout.HotelCode as string])
  );
  const member = useAppSelector((state) => state.member);
  const memberProfile = useAppSelector((state) => state.member.crmProfile);
  useEffect(() => {
    const rateArrays = [];
    let offerCodes = [];
    let selectedPrice = 0;
    const roomsArray = Object.values(rooms) || [];

    const _roomRate = roomsArray.length ? roomsArray[0].rate : {};
    let selectedRate = {
      ..._roomRate,
      Active: true,
    };
    let roomOffers = {};
    roomsArray.forEach(function (room, idx) {
      if (room.room && room.rate) {
        roomOffers = { ...roomOffers, [room.id]: { Rates: {} } };
        const rateArray = [];
        const rateSelected = room.rate;
        if (selectedRate.RateCode === rateSelected.RateCode) {
          selectedRate.name = rateSelected.name;
          selectedRate.description = rateSelected.description;
          selectedRate.rateCode = rateSelected.rateCode;
          selectedRate.rateType = rateSelected.rateType;
          selectedRate.MultiRates = false;
        } else {
          selectedRate = {
            ...selectedRate,
            ...{
              name: "Your Price",
              description: "",
              rateCode: "",
              rateType: "",
              MultiRates: true,
            },
          };
        }

        selectedPrice += parseFloat(rateSelected.RoomRate);
        Object.values(room.room.Rates).forEach(function (rate) {
          // Filter out the currently selected rate.
          // if (isMultiRoom && rateSelected.RateCode !== rate.RateCode) {
          rateArray.push(rate.RateCode);

          // }
        });
        rateArrays.push(rateArray);
      }
    });
    if (rateArrays.length) {
      // Find common rates among rooms.
      offerCodes = intersection(...rateArrays);
    }
    if (offerCodes.length) {
      roomsArray.forEach(function (room) {
        const roomRates = [];
        if (room.room) {
          room.room.Rates.forEach(function (rate) {
            if (offerCodes.includes(rate.RateCode)) {
              roomRates.push(rate);
            }
          });
          roomOffers[room.id].Rates = convertArrayToObject(
            roomRates,
            "RateCode"
          );
        }
      });
    }
    let commonOffers = {};
    const offerCount = Object.values(roomOffers).length;
    Object.values(roomOffers).forEach(function (roomOffer, index) {
      const offerRates = Object.values(roomOffer.Rates);
      offerRates.forEach(function (offerRate) {
        const match = Object.keys(commonOffers).includes(offerRate.RateCode);
        if (!match) {
          const commonOffer = {
            RateCode: offerRate.RateCode,
            rateType: offerRate.rateType,
            name: offerRate.name,
            description: offerRate.description,
            roomRate: parseFloat(offerRate.roomRate),
            currencyCode: offerRate.currencyCode,
            cancelPolicy: offerRate.cancelPolicy,
            guarantee: offerRate.guarantee,
          };
          commonOffers = {
            ...commonOffers,
            [offerRate.RateCode]: commonOffer,
          };
        } else {
          // Add to the existing rate total.
          commonOffers[offerRate.RateCode].roomRate += parseFloat(
            offerRate.roomRate
          );
        }
      });
    });
    const averageOffers = [];
    Object.values(commonOffers).forEach(function (commonOffer) {
      // Convert RoomRate totals to average nightly rate.
      commonOffer.roomRate = commonOffer.roomRate / offerCount;
      averageOffers.push(commonOffer);
    });
    selectedRate.roomRate = selectedPrice / roomsArray.length;
    // selectedRate.Active = true;
    setCurrentRate(selectedRate);

    // averageOffers.push(selectedRate);
    averageOffers.sort((f, s) => {
      return f.roomRate - s.roomRate;
    });

    const getPoints = async (memberId: string) => {
      const response = await getMemberPoints(memberId);
      if (response?.memberPointsData && response?.memberPointsData?.available) {
        dispatch(setMemberPointsTally(response.memberPointsData.available));
        return response.memberPointsData.available;
      } else {
        return 0;
      }
    };

    const isRedemptionRateCodeInArray = averageOffers.some((obj) =>
      TP_REDEMPTION_RATE.includes(obj.RateCode)
    );

    // move selected offer to top
    // if(moveSelectedOfferToTop) {
    //   const selectedOfferIndex = averageOffers.findIndex(averageOffers => averageOffers.RateCode == selectedRate.RateCode);
    //   selectedOfferIndex != -1 && averageOffers.push(...averageOffers.splice(0, selectedOfferIndex));
    // }
    if (isRedemptionRateCodeInArray) {
      if (member?.isLoggedIn) {
        let isEligible = false;
        const redemptionItem =
          rates[checkout.HotelCode as string]?.redemptionItem;
        if (
          redemptionItem &&
          redemptionItem?.currencyRequired &&
          memberProfile?.memberId
        ) {
          getPoints(memberProfile?.memberId).then((memberPoints) => {
            const numNights = differenceInDays(
              new Date(checkout.End),
              new Date(checkout.Start)
            );
            const totalUserPoints =
              parseInt(memberPoints) + checkout?.pointsUsedByUser;
            isEligible = checkUserRedemptionEligiblity(
              totalUserPoints,
              parseInt(redemptionItem?.currencyRequired),
              numNights
            );
            const redemptionRateIndex = averageOffers.findIndex((obj) =>
              TP_REDEMPTION_RATE.includes(obj.RateCode)
            );
            const redemptionRateObject = averageOffers.splice(
              redemptionRateIndex,
              1
            )[0];

            if (isEligible) {
              // Adding rate to top
              averageOffers.unshift(redemptionRateObject);
            } else {
              //Adding rate to bottom
              averageOffers.push(redemptionRateObject);
            }

            if (
              !redemptiomRateFilterFlag &&
              moveSelectedOfferToTop &&
              !isEligible
            ) {
              const newSelectedRate = {
                ...averageOffers[0],
                Active: true,
                MultiRates: false,
              };
              setCurrentRate(newSelectedRate);
              setredemptiomRateFilterFlag(true);
              const rateCode = averageOffers[0].RateCode;
              const selectedOffer = offers.filter(
                (o) => o.RateCode == rateCode
              )[0];
              setCurrentRate(selectedOffer);
              let currentRooms = { ...rooms };
              Object.values(currentRooms).forEach(function (room: IRoom) {
                const _rate = room.room.Rates.filter(
                  (roomRate: { RateCode: any }) =>
                    roomRate.RateCode === rateCode
                )[0];
                const _services = null;
                currentRooms = {
                  ...currentRooms,
                  [room.id]: { ...room, rate: _rate, services: _services },
                };
              });
              const newCheckout = { ...checkout, Rooms: currentRooms };
              dispatch(setEditReservationCheckout(newCheckout));
            }
            setOffers(averageOffers);
          });
        } else {
          //Filtering Redemption rates out if not able to calculate eligibility for a user.
          const filteredArray = averageOffers.filter(
            (obj) => !TP_REDEMPTION_RATE.includes(obj.RateCode)
          );
          setOffers(filteredArray);
        }
      } else {
        //Filtering Redemption rates out if user is not logged in
        const filteredArray = averageOffers.filter(
          (obj) => !TP_REDEMPTION_RATE.includes(obj.RateCode)
        );
        setOffers(filteredArray);
      }
    } else {
      setOffers(averageOffers);
    }
    // setOffers(averageOffers);
  }, [rooms]);

  const handleSelectRate = (event: React.MouseEvent<HTMLElement>) => {
    setMoveSelectedOfferToTop(false);
    const rateCode = event.target.value;
    const selectedOffer = offers.filter((o) => o.RateCode == rateCode)[0];
    setCurrentRate(selectedOffer);
    let currentRooms = { ...rooms };
    Object.values(currentRooms).forEach(function (room: IRoom) {
      const _rate = room.room.Rates.filter(
        (roomRate) => roomRate.RateCode === rateCode
      )[0];
      const _services = null;
      currentRooms = {
        ...currentRooms,
        [room.id]: { ...room, rate: _rate, services: _services },
      };
      //   currentRooms[room.id].rate = room.room.Rates.filter(roomRate => roomRate.RateCode === rateCode)[0];
      //   currentRooms[room.id].services = null;
    });
    const newCheckout = { ...checkout, Rooms: currentRooms };
    dispatch(setEditReservationCheckout(newCheckout));
  };

  return (
    <div className="mt-4 mb-4 rounded p-0">
      <RateList show={"d-block"}>
        {offers.length > 0 ? (
          <>
            {currentRate?.MultiRates && (
              <RateListItem
                offerId={`current-rate`}
                rate={currentRate}
                currentRate={currentRate}
                handleSelectRate={handleSelectRate}
                perNight={perNight}
                crsCode={checkout.HotelCode}
                hideUnlockLockBtn={true}
              />
            )}
            {offers.map((rate, i) => {
              return (
                <RateListItem
                  key={`rl${i}-${rate.RateCode}`}
                  offerId={`offer-${i + 1}`}
                  rate={rate}
                  currentRate={currentRate}
                  handleSelectRate={handleSelectRate}
                  perNight={perNight}
                  crsCode={checkout.HotelCode}
                  hideUnlockLockBtn={true}
                />
              );
            })}
          </>
        ) : // <div>No offers available</div>
        null}
      </RateList>
    </div>
  );
};
export default NewEditOffers;
